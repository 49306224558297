@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-family: 'Lugrasimo', cursive;
  scroll-behavior: smooth;
}



.bg-contact {
  background-image: url(./assets/contact-bg.jpg);
  width: 100%;
  background-size: cover;
    z-index: 10;
}

.bg-services {
  background-image: url(./assets/strukturierter-goldhintergrund.jpg);
  width: 100%;
    
}

.bg {
   background-image: url(./assets/bunter-hintergrund-mit-sternen-und-bokeh-lichteffekt.jpg);
  width: 100%;
    
    background-size: cover;
    box-shadow: inset 100px 100px 400px 110px rgba(172, 147, 147, 0.8);
    filter: brightness(100%);
    filter: sepia(20%);
    
}

.bg-about {
  background-image: url(./assets/über.jpg);
  width: 100%;
    
}

.bg-about-container {
  background: rgb(222,195,26);
background: linear-gradient(45deg, rgba(222,195,26,1) 0%, rgba(222,195,26,0.9836309523809523) 97%);
}

.hoverAnimation {
    @apply hover:cursor-pointer hover:text-[#B09B71] hover:border-b-2 border-b-[#B09B71] transition duration-200 ease-out hover:ease-in
}



.heading-color {
    color: white;
    text-shadow: 4px 4px 8px #000000;
  
}

.small-heading {
  color: #745944;
}

.text-color {
  color: #5208088a;
}

/* Seperator */ 


.seperator::before {
  float: left;
}
.seperator::after {
  float: right;
}

.seperator::before,
.seperator::after {
  display: block;
    width: 100%;
    max-width: 250px;
    content: "";
    margin-top: 28px;
    border: 1px solid white;
  

}

/* Seperator 2 */
.seperator2::before {
  float: left;
  
}

.seperator2::after {
  float: right;
}

.seperator2::before,
.seperator2::after {
  display: block;
    width: 100%;
    max-width: 100px;
    content: " ";
    margin-top: 28px;
    border: 1px solid white;

}

.text-shadow {
  text-shadow: 4px 4px 8px #000000;
}

.box-shadow {
  box-shadow: 0 0 300px rgba(0,0,0,1) inset;
}

.box-shadow-box {
  box-shadow: 0 0 150px rgba(0,0,0,0.8) inset;
}

/* Contact form*/



body,
input,
textarea {
  font-family: "Poppins", sans-serif;
}

.container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  width: 100%;
  max-width: 820px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contact-form {
  background-color: #B09B71;
  position: relative;
}

.circle {
  border-radius: 50%;
  background: linear-gradient(135deg, #B09B71 20%, #a88863);
  position: absolute;
}

.circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

.contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: #a88863;
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

form {
  padding: 2.3rem 2.2rem;
  z-index: 1;
  overflow: hidden;
  position: relative;
}

.title {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 32px;
}

.input-container {
  position: relative;
  margin: 1rem 0;
  color: white;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 25px;
  transition: 0.3s;
}

.input::placeholder {
  color: white;
}


textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 22px;
  resize: none;
  overflow-y: auto;
}

.btn {
  padding: 0.6rem 1.3rem;
  background-color: #B09B71;
  border: 2px solid #B09B71;
  font-size: 1.25rem;
  color: #F6E7D8;
  line-height: 1;
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 2;
}

.btn:hover {
  background-color: #E3CAA5 !important;
  color: white;
  
}

.btn-contact {
  
    padding: 0.6rem 1.3rem;
    background-color: #a88863;
    border: 2px solid #fafafa;
    font-size: 0.95rem;
    color: white;
    line-height: 1;
    border-radius: 25px;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin: 0;
  }
  
.btn-contact:hover {
    background-color: #dbc49f;
    color: #a88863;
  }

  .filter {
    filter: contrast(0);
    filter: brightness(20%);
  }


.input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}

.input-container span:before,
.input-container span:after {
  content: "";
  position: absolute;
  width: 10%;
  opacity: 0;
  transition: 0.3s;
  height: 5px;
  background-color: #a88863;
  top: 50%;
  transform: translateY(-50%);
}

.input-container span:before {
  left: 50%;
}

.input-container span:after {
  right: 50%;
}

.input-container.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.input-container.focus span:before,
.input-container.focus span:after {
  width: 50%;
  opacity: 1;
}

.contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-info .title {
  color: #a88863;
}

.text {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}

.information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
}

.icon {
  width: 28px;
  margin-right: 0.7rem;
}

.social-media {
  padding: 2rem 0 0 0;
}

.social-media p {
  color: #333;
}

.social-icons {
  display: flex;
  margin-top: 0.5rem;
}

.social-icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: linear-gradient(45deg, #dbc49f, #a88863);
  color: #fff;
  text-align: center;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.3s;
}

.social-icons a:hover {
  transform: scale(1.05);
}

.contact-info:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid #a88863;
  border-radius: 50%;
  bottom: -77px;
  right: 50px;
  opacity: 0.3;
}

.big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #dbc49f, #a88863);
  bottom: 50%;
  right: 50%;
  transform: translate(-40%, 38%);
}

.big-circle:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #fafafa;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  
  transform: translate(181%, 11%);
filter: contrast(0);
filter: brightness(15%);

  opacity: 1;
}

@media (max-width: 850px) {
  .form {
    grid-template-columns: 1fr;
  }

  .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .square {
    transform: translate(140%, 43%);
    height: 350px;
  }

  .big-circle {
    bottom: 75%;
    transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .text {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    padding: 1.5rem 0 0 0;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1.5rem;
  }

  .contact-info:before {
    display: none;
  }

  .square,
  .big-circle {
    display: none;
  }

  form,
  .contact-info {
    padding: 1.7rem 1.6rem;
  }

  .text,
  .information,
  .social-media p {
    font-size: 0.8rem;
  }

  .title {
    font-size: 1.15rem;
  }

  .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .icon {
    width: 23px;
  }

  .input {
    padding: 0.45rem 1.2rem;
  }

  .btn {
    padding: 0.45rem 1.2rem;
  }
}

